<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Beat Name</label>
            <InputText v-model="localItem.clusterbeat_name" :class="{ 'p-invalid': errorEdit?.clusterbeat_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit?.clusterbeat_name">{{ errorEdit?.clusterbeat_name[0] }}</small>
        </div>
        <div class="field">
            <label>Description</label>
            <Textarea v-model="localItem.clusterbeat_description" :autoResize="true" rows="5" cols="30" :class="{ 'p-invalid': errorEdit?.clusterbeat_description }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit?.clusterbeat_description">{{ errorEdit?.clusterbeat_description[0] }}</small>
        </div>
        <div class="field">
            <label>Beat Color</label><br>
            <ColorPicker v-model="localItem.clusterbeat_color" :inline="true" /> <br>
            <InputText v-model="formattedColor" :class="{ 'p-invalid': errorEdit?.clusterbeat_color }" :disabled="true"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit?.clusterbeat_color">{{ errorEdit?.clusterbeat_color[0] }}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["item"],
    data() {
        return {
            loading: false,
            editItemDialog: false,
            localItem: { ...this.item },
        };
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if (this.editItemDialog) {
                        this.localItem = { ...this.item }; // Sync localItem with item prop
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        },
    },
    computed: {
        ...mapGetters(['errorEdit']),
        formattedColor() {
            // Ensure the color value always starts with '#'
            if (this.localItem.clusterbeat_color && !this.localItem.clusterbeat_color.startsWith('#')) {
                return `#${this.localItem.clusterbeat_color}`;
            }
            return this.localItem.clusterbeat_color;
        }
    },
    methods: {
        hideDialog() {
            this.editItemDialog = false;
        },
        updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/cluster-beat/update',
                data: {
                    "clusterbeat_id": this.localItem.clusterbeat_id,
                    "clusterbeat_name": this.localItem.clusterbeat_name,
                    "clusterbeat_color": this.formattedColor,
                    "clusterbeat_description": this.localItem.clusterbeat_description
                },
            })
                .then((res) => {
                    this.loading = false;
                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000 });
                        this.$store.commit('setErrors', {});
                        this.$store.commit('setErrorEdit', {});
                        this.editItemDialog = false;
                        this.$emit('submit');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$store.commit('setErrorEdit', err?.response?.data?.data);
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000 });
                });
        },
    }
}
</script>
