<template>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12">
            <div class="card ">
                <div class="font-medium text-3xl text-900 mb-3">PROFILE CLUSTER (By Numbers of Outlets)
                    <button class="p-panel-header-icon p-link" @click="editItem">
                        <span class="pi pi-cog"></span>
                    </button>
                </div>
                <div class="text-500 mb-5">Here are the details of the cluster profile.</div>
                <div class="grid grid-nogutter border-top-1 surface-border pt-2">
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">
                            Cluster Name
                        </div>
                        <div class="text-900">
                            {{item?.trans_cluster?.cluster_name}}
                        </div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">
                            Numbers of Outlets
                        </div>
                        <div class="text-900">
                            {{item?.trans_cluster?.cluster_n_value}}
                        </div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">
                            Cluster Description
                        </div>
                        <div class="text-900">
                            {{item?.trans_cluster?.cluster_description}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Cluster Name</label>
            <InputText 
				v-model="data.cluster_name" required="true" autofocus :class="{ 'p-invalid': errorEdit?.cluster_names }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit?.cluster_name" >{{ errorEdit?.cluster_name[0] }}</small >
        </div>
         <div class="field">
            <label>Number of Outlets per Beat</label>
            <InputNumber  v-model="data.cluster_n_value" mode="decimal" :min="0" :useGrouping="false" :class="{ 'p-invalid': errorEdit?.cluster_n_value }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit?.cluster_n_value" >{{ errorEdit?.cluster_n_value[0] }}</small >
        </div>
         <div class="field">
            <label>Description</label>
            <Textarea v-model="data.cluster_description" :autoResize="true" rows="5" cols="30" :class="{ 'p-invalid': errorEdit?.cluster_description }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit?.cluster_description" >{{ errorEdit?.cluster_description[0] }}</small >
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    emits: ["submit","update-loading-card"],
    props: ['item'],
    data() {
        return {
            loading: false,
            loadingCard: false,

			editItemDialog: false,
			data: null,
			project_id: null
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
		this.project_id = this.$route.params.id;
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
						// 
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
	methods: {
        // EDIT
		editItem(){
            this.data = JSON.parse(JSON.stringify(this.item?.trans_cluster)); // Membuat salinan independen
			this.editItemDialog = true;
		},
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/territory-mapping/create',
                data: {
					"project_id": this.project_id,
					"clustertype_id": this.item.clustertype_id,
					"cluster_name": this.data.cluster_name,
					"cluster_description": this.data.cluster_description,
					"cluster_n_value": this.data.cluster_n_value,
					"cluster_slot_no": this.item.clusterslot_id,
					"cluster_id": this.data.cluster_id
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
					this.$emit('update-loading-card', true); // Emit event ke view.vue
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err?.response?.data?.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
				// this.$emit('update-loading-card', false); // Emit event ke view.vue
            });
		},
	}
}
</script>