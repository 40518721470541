<template>
    <Dialog v-model:visible="ItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>From Beat</label>
            <Dropdown dataKey="clusterbeat_id" v-model="forms.from_clusterbeat_id" :loading="loadingDropdownFromBeat"
                :options="dataDropdownFromBeat" :class="{ 'p-invalid': errorAdd?.from_clusterbeat_id }"
                optionLabel="clusterbeat_name" optionValue="clusterbeat_id" placeholder="Select Beat"
                :filter="true" :showClear="true" @filter="searchDropdownFromBeat($event, 'add')"
                @change="changeOutlet()" />
            <small class="p-invalid" style="color: red" v-if="errorAdd?.from_clusterbeat_id">{{
                errorAdd?.from_clusterbeat_id[0]
            }}</small>
        </div>
        <div class="field">
            <label>Outlet</label>
            <div class="field-checkbox mt-2">
                <Checkbox id="isAllOutlet" v-model="forms.checked" :binary="true" :disabled="forms.from_clusterbeat_id == null" @change="this.forms.outlet_id = [];"/>
                <label for="isAllOutlet">Check if selecting all outlets</label>
            </div>
            <Listbox v-model="forms.outlet_id" :disabled="forms.checked" dataKey="outlet_id" :options="dataDropdownOutlet" :class="{ 'p-invalid': errorAdd?.outlet_id }" :multiple="true" :filter="true" optionLabel="label" optionValue="outlet_id" listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownOutlet($event, 'add')">
                <template #option="slotProps">
                    <div class="country-item">
                        <div>{{slotProps.option.label}}</div>
                    </div>
                </template>
            </Listbox>
            <small class="p-invalid" style="color: red" v-if="errorAdd?.outlet_id">{{
                errorAdd?.outlet_id[0]
            }}</small>
        </div>
        <div class="field">
            <label>To Beat</label>
            <Dropdown dataKey="clusterbeat_id" v-model="forms.to_clusterbeat_id" :loading="loadingDropdownToBeat"
                :options="dataDropdownToBeat" :class="{ 'p-invalid': errorAdd?.to_clusterbeat_id }"
                optionLabel="clusterbeat_name" optionValue="clusterbeat_id" placeholder="Select Beat"
                :filter="true" :showClear="true" @filter="searchDropdownToBeat($event, 'add')" />
            <small class="p-invalid" style="color: red" v-if="errorAdd?.to_clusterbeat_id">{{
                errorAdd?.to_clusterbeat_id[0]
            }}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" :loading="loading"/>
        </template>
    </Dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["item"],
    data() {
        return {
            loading: false,
            loadingDropdownFromBeat: false,
            loadingDropdownToBeat: false,
            loadingDropdownOutlet: false,

            // dataDropdown,
            dataDropdownFromBeat: null,
            dataDropdownToBeat: null,
            dataDropdownOutlet: null,

            ItemDialog: false,
            localItem: { ...this.item },

            // addNew
            forms: {
                from_clusterbeat_id: null,
                to_clusterbeat_id: null,
                checked: false,
                outlet_id: []
            },
        };
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    watch: {
        ItemDialog: {
            handler() {
                setTimeout(() => {
                    if (this.ItemDialog) {
                        this.localItem = { ...this.item }; // Sync localItem with item prop
                        this.searchDropdownFromBeat('');
                        this.searchDropdownToBeat('');
                    }
                    this.$store.commit('setErrorAdd', {});
                }, 500);
            },
        },
    },
    computed: {
        ...mapGetters(['errorAdd']),
    },
    methods: {
        hideDialog() {
            this.ItemDialog = false;
        },
        // DROPDOWN
        searchDropdownFromBeat(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dfb.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownFromBeat = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/trans-cluster-beat',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "cluster_id" : this.localItem.trans_cluster.cluster_id
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownFromBeat = res.data.data;
                        this.loadingDropdownFromBeat = false;
                    } else if (purpose == null) {
                        this.dataDropdownFromBeat = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownToBeat(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dtb.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownToBeat = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/trans-cluster-beat',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "cluster_id" : this.localItem.trans_cluster.cluster_id
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownToBeat = res.data.data;
                        this.loadingDropdownToBeat = false;
                    } else if (purpose == null) {
                        this.dataDropdownToBeat = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeOutlet() {
            this.forms.checked =  false;
            this.forms.outlet_id = [];
            this.dataDropdownOutlet = null;
            this.searchDropdownOutlet('');
        },
        searchDropdownOutlet(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.from_clusterbeat_id) {

                    if (valueEdit) {
                        this.$refs.doutlet.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownOutlet = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/trans-cluster-beat-outlet',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "clusterbeat_id": this.forms.from_clusterbeat_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownOutlet = res.data.data;
                                this.loadingDropdownOutlet = false;
                            } else if (purpose == null) {
                                this.dataDropdownOutlet = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        save() {
            this.loading = true;
            
            if(this.forms.from_clusterbeat_id != null){
                if(this.forms.outlet_id.length == 0 && this.forms.checked == false){
                    alert("Please check or fill in the available outlets");
                    this.loading = false;
                    return;
                }
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/cluster-beat-outlet/create',
                data: {
                    "from_clusterbeat_id": this.forms.from_clusterbeat_id,
                    "to_clusterbeat_id": this.forms.to_clusterbeat_id,
                    "cluster_id": this.localItem.trans_cluster.cluster_id,
                    "project_id": this.localItem.trans_cluster.project_id,
                    "is_all": this.forms.checked,
                    "outlet_id": this.forms.outlet_id
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000 });
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.ItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err?.response?.data?.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000 });
            });
        },
        clearForms() {
            this.forms.from_clusterbeat_id = null;
            this.forms.checked = false;
            this.forms.outlet_id = [];
            this.forms.to_clusterbeat_id = null;

            this.changeOutlet();
        },
    }
}
</script>
