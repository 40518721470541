<template>
<div v-if="reloadForm == false">
     <div v-if="itemContent">
        <div v-if="itemContent.trans_cluster">
            <View ref="view" :item="itemContent" @submit="getContent(project_id, clusterslot_id, 'y')" @update-loading-card="handleUpdateLoadingCard"/>
        </div>
        <div v-else>
            <Create :item="itemContent" @submit="getContent(project_id, clusterslot_id, 'n')"/>
        </div>
     </div>
    <div v-else>
        <h3 class="text-center">Content Not Found</h3>
    </div>
</div>
<div v-else>
    <SkeletonComponent />
</div>
</template>


<script>
import SkeletonComponent from '../../../../../SkeletonComponent.vue';
import Create from './Create.vue';
import View from './View.vue';

export default {
    components: {
		'SkeletonComponent': SkeletonComponent,
		'Create': Create,
		'View': View,
	},
    data() {
        return {
            // loading
            reloadForm: false,

            project_id: null,
            clusterslot_id: null,

            itemContent: {},
        }
    },
    created() {
        this.project_id = this.$route.params.id;
        this.clusterslot_id = this.$route.params.clusterslot_id;
        this.getContent(this.project_id, this.clusterslot_id);
    },
    methods: {
        getContent(id, clusterslot_id, is_update) {
            this.reloadForm = is_update == 'n' ? true : false;

            if (is_update == 'y' && this.$refs.view) {
                this.$refs.view.loadingCard = true; // Mengakses loadingCard jika View sudah tersedia
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/territory-mapping/detail',
                params: {
                    "project_id": id,
                    "clusterslot_id": clusterslot_id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (is_update == 'y' && this.$refs.view) {
                    this.$refs.view.loadingCard = false; // Mengakses loadingCard jika View sudah tersedia
                }

                if (res) {
                    this.$store.commit('setErrors', {});
                    this.itemContent = res.data.data;
                }
            }, (this))
            .catch((err) => {
                this.itemContent = null;
                console.log(err);
                this.reloadForm = false;

                if (is_update == 'y' && this.$refs.view) {
                    this.$refs.view.loadingCard = false; // Mengakses loadingCard jika View sudah tersedia
                }

            });
        },
        handleUpdateLoadingCard(value) {
            if (this.$refs.view) {
                this.$refs.view.loadingCard = value; // Mengakses loadingCard jika View sudah tersedia
            }

            this.getContent(this.project_id, this.clusterslot_id, 'y')

        }
    }
}
</script>