<template>
    <div class="card">
        <div class="grid formgrid">
            <div class="field col-12 md:col-12">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="10rem"></Skeleton>
            </div>
        </div>
    </div>
</template>