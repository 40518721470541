<template>
<BlockUI :blocked="loading">
    <div class="card mt-2" style="margin-bottom: 2em">
        <h5>Map</h5>
        <hr>
        <Fieldset legend="Update" :toggleable="true" :collapsed="true" class="mb-3">
            <div class="p-fluid">
                <div class="formgrid grid">
                    <div class="field col-12 md:col-3 lg:col-3">
                        <label>Outlet</label>
                        <Chips v-model="forms.outlet_ids" optionValue="clusterbeat_id" :class="{ 'p-invalid': errorAdd?.outlet_id }" @remove="removeOutletFromIcons">
                            <template #chip="slotProps">
                                <div>
                                    <span>{{slotProps.value.name}}</span>
                                </div>
                            </template>
                        </Chips>
                        <small class="p-invalid" style="color: red" v-if="errorAdd?.outlet_id">{{
                            errorAdd?.outlet_id[0]
                        }}</small>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3">
                        <label>Action</label>
                        <Dropdown  v-model="forms.action" 
                            :options="dataDropdownAction" :class="{ 'p-invalid': errorAdd?.action }"
                            optionLabel="name" optionValue="code" placeholder="Select Action" @click="actionClick"/>
                        <small class="p-invalid" style="color: red" v-if="errorAdd?.action">{{
                            errorAdd?.action[0]
                        }}</small>
                    </div>
                </div>
                <div class="formgrid grid" v-if="forms.action == 'move'">
                    <div class="field col-12 md:col-3 lg:col-3">
                        <label>To Beat</label>
                        <Dropdown dataKey="clusterbeat_id" v-model="forms.to_clusterbeat_id" :loading="loadingDropdownToBeat"
                            :options="dataDropdownToBeat" :class="{ 'p-invalid': errorAdd?.to_clusterbeat_id }"
                            optionLabel="clusterbeat_name" optionValue="clusterbeat_id" placeholder="Select Beat"
                            :filter="true" :showClear="true" @filter="searchDropdownToBeat($event, 'add')" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd?.to_clusterbeat_id">{{
                            errorAdd?.to_clusterbeat_id[0]
                        }}</small>
                    </div>
                </div>
            </div>
            <Button :loading="loadingSave" label="Save" icon="pi pi-save" class="p-button-primary" @click="save" />
        </Fieldset>
        <!-- Toggle Button -->
        <div class="mb-3">
            <SelectButton v-model="buttonShowMarker" :options="['Show Marker','Hide Marker']" @click="toggleMarkers()"/>
        </div>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-12">
                <div class="map-without-polygon" id="map-without-polygon"></div>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-6">
                <div class="card mt-2" style="margin-bottom: 2em">
                    <h6>Data - Beat</h6>
                    <div class="datatable-container" style="height: 550px; overflow-y: auto;">
                        <DataTable :value="dataTable2" responsiveLayout="scroll" :loading="loadingDatatable2" dataKey="number" @sort="onSortPolygon($event)">
                            <template #header>
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <div>
                                        <!--  -->
                                    </div>
                                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="search2" placeholder="Search..." @keyup.enter="getDataTablePolygon" />
                                    </span>
                                </div>
                            </template>
                            <Column :style="{width:'20px'}">
                                <template #body="slotProps">
                                    <Button icon="pi pi-eye" class="p-button-rounded p-button mr-2" @click="zoomToCoordinatePolygon(slotProps.data.clusterbeat_color)" />
                                </template>
                            </Column>
                            <Column field="number" header="#" :style="{width:'20px'}" >
                                <template #body="slotProps">
                                {{ slotProps.data.number }}
                                </template>
                            </Column>
                            <Column field="clusterbeat_name" header="Beat Name">
                                <template #body="slotProps">
                                    {{slotProps.data.clusterbeat_name}}
                                </template>
                            </Column>
                            <Column field="clusterbeat_color" header="Color">
                                <template #body="slotProps">
                                    <ColorPicker v-model="slotProps.data.clusterbeat_color" :disabled="true"/>  {{slotProps.data.clusterbeat_color}}
                                </template>
                            </Column>
                            <Column field="clusterbeat_description" header="Description">
                                <template #body="slotProps">
                                    {{slotProps.data.clusterbeat_description}}
                                </template>
                            </Column>
                            <Column header="Action">
                                <template #body="slotProps">
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" />
                                </template>
                            </Column>
                            <template #empty>
                                No data found.
                            </template>
                            <template #loading>
                                Loading data. Please wait.
                            </template>
                            <template #footer>
                                In total there are {{totalItemsCount2 ? totalItemsCount2 : 0 }} data.
                            </template>
                        </DataTable>

                        <Paginator v-model:rows="rows2" v-model:first="offset2" :totalRecords="totalItemsCount2" :rowsPerPageOptions="[10,20,30]">
                            <template>
                                {{totalItemsCount2}}
                            </template>
                        </Paginator>

                        <EditBeat @submit="updateBeat()" :item="itemEdit" ref="editbeat"/>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <div class="card mt-2" style="margin-bottom: 2em">
                    <h6>Data - Outlet</h6>
                    <div class="datatable-container" style="height: 550px; overflow-y: auto;">
                        <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loadingDatatable" dataKey="number" @sort="onSort($event)">
                            <template #header>
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <div>
                                        <!--  -->
                                        <Button label="Bulk Action" class="p-button-raised p-button-secondary" @click="bulkActionOutlet()"/>
                                    </div>
                                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                                    </span>
                                </div>
                            </template>
                            <Column :style="{width:'20px'}">
                                <template #body="slotProps">
                                    <Button icon="pi pi-eye" class="p-button-rounded p-button mr-2" @click="zoomToCoordinate(slotProps.data.outlet_latitude, slotProps.data.outlet_longitude)" />
                                </template>
                            </Column>
                            <Column field="number" header="#" :style="{width:'20px'}" >
                                <template #body="slotProps">
                                {{ slotProps.data.number }}
                                </template>
                            </Column>
                            <Column field="clusterbeat_name" header="Beat Name">
                                <template #body="slotProps">
                                    {{slotProps.data.clusterbeat_name}}
                                </template>
                            </Column>
                            <Column field="clusterbeat_color" header="Color">
                                <template #body="slotProps">
                                <ColorPicker v-model="slotProps.data.clusterbeat_color" :disabled="true"/>  {{slotProps.data.clusterbeat_color}}
                                </template>
                            </Column>
                            <Column field="outlet_code" header="Outlet Code">
                                <template #body="slotProps">
                                    {{slotProps.data.outlet_code}}
                                </template>
                            </Column>
                            <Column field="outlet_name" header="Outlet Name">
                                <template #body="slotProps">
                                    {{slotProps.data.outlet_name}}
                                </template>
                            </Column>
                            <Column field="outlet_type_name" header="Outlet Type">
                                <template #body="slotProps">
                                    {{slotProps.data.outlet_type_name}}
                                </template>
                            </Column>
                            <template #empty>
                                No data found.
                            </template>
                            <template #loading>
                                Loading data. Please wait.
                            </template>
                            <template #footer>
                                In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                            </template>
                        </DataTable>

                        <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                            <template>
                                {{totalItemsCount}}
                            </template>
                        </Paginator>

                        <BulkAction @submit="updateBeat()" :item="itemBulkAction" ref="bulkaction"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</BlockUI>
</template>

<script>
import { numberingDatatable } from '../../../../../../utils/helpers';
import BulkAction from './BulkAction.vue';
import { mapGetters } from "vuex";
import EditBeat from './EditBeat.vue';

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup({
        disableClusteringAtZoom: 5,
        maxClusterRadius: 100,
    });

export default {
    components: {
		'BulkAction': BulkAction,
		'EditBeat': EditBeat,
	},
    props: [
		"filters",
        "filterButton",
        "loadingFilter",
        "item"
    ],
    data() {
        return {
            loading: false,
            loadingSave: false,
            loadingDatatable: false,
            loadingDatatable2: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingDropdownToBeat: false,

            buttonShowMarker: "Show Marker",

            // edit
            itemEdit: {},

            // dataDropdown,
            dataDropdownToBeat: null,
            dataDropdownAction: [
                { name: 'Remove', code: 'remove' },
                { name: 'Move', code: 'move' },
            ],
            itemBulkAction: {},

            forms : {
                outlet_ids: [],
                to_clusterbeat_id: null,
                action: 'remove'
            },

            //maps
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            location_total: 0,
            marker_list: [],
            polygons: [],
            bounds: null,

            dist_lat: null,
            dist_lng: null,

            //datatables outlet
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,

            //datatables polygon
            data2: null,
            dataTable2: null,
            rows2: 10,
            search2: null,
            offset2: null,
            field2: null,
            sort2: null,
            page2: null,
            totalItemsCount2: 0,
        }
    },
    created(){
        this.showMarker();
    },
    mounted() {
        this.getDataTable();
        this.getDataTablePolygon();
        this.initMap();
        this.searchDropdownToBeat('');

        window.getOutletFromId = this.getOutletFromId.bind(this);
        window.removeOutletFromIcon = this.removeOutletFromIcon.bind(this);

        this.$store.commit('setErrorAdd', {});
    },
    computed: {
        ...mapGetters(['errorAdd']),
    },
    watch: {
        filterButton: {
            handler() {
                setTimeout(() => {
                    this.showMarker();
                    this.getDataTable();
                    this.getDataTablePolygon();

                    this.buttonShowMarker = "Show Marker";

                    this.searchDropdownToBeat('');
                }, 200);
            },
        },
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        offset2() {
            this.getDataTablePolygon();
        },
        rows2() {
            this.getDataTablePolygon();
        },
    },
    methods:{
        convertMultiOutlet(data) {
            if (data) {
                return data.map((item) => {
                    return item.id;
                });
            }
        },
        // DROPDOWN
        searchDropdownToBeat(event, purpose, valueEdit) {
            setTimeout(() => {
                
                if (valueEdit) {
                    this.$refs.dtb.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownToBeat = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/trans-cluster-beat',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "cluster_id" : this.item.trans_cluster.cluster_id
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownToBeat = res.data.data;
                        this.loadingDropdownToBeat = false;
                    } else if (purpose == null) {
                        this.dataDropdownToBeat = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        actionClick(){
            this.forms.to_clusterbeat_id =  null;
        },
        // EDIT BEAT
		editItem(item) {
			this.itemEdit = item;
            
            this.$refs.editbeat.editItemDialog = true;
		},
        // MOVE OUTLET
        save() {
            this.loadingSave = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/cluster-beat-outlet/create-maps',
                data: {
                    "to_clusterbeat_id": this.forms.to_clusterbeat_id,
                    "cluster_id": this.item.trans_cluster.cluster_id,
                    "outlet_id": this.convertMultiOutlet(this.forms.outlet_ids),
                    "action": this.forms.action,
                    "project_id": this.item.trans_cluster.project_id,
                },
            })
            .then((res) => {
                this.loadingSave = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000 });
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('update-loading-card', true); // Emit event ke view.vue
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingSave = false;
                this.$store.commit('setErrorAdd', err?.response?.data?.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000 });
            });
        },
        clearForms(){
            this.forms.to_clusterbeat_id = null;
            this.forms.outlet_ids = [];
            this.forms.action = 'remove';
        },
        // BULK ACTION
        bulkActionOutlet(){
			this.itemBulkAction = this.item;

            this.$refs.bulkaction.ItemDialog = true;
        },
        updateBeat(){
            this.showMarker();
            this.getDataTable();
            this.getDataTablePolygon();
            
            this.$emit('update-loading-card', true); // Emit event ke view.vue
        },
        // DATATABLE
        getDataTable(){
          
            this.loadingDatatable=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/cluster-beat-outlet',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "cluster_id": this.item.trans_cluster.cluster_id,
                    "clusterbeat_id": this.filters.clusterbeat_id,
                    "project_id": this.item.trans_cluster.project_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loadingDatatable=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loadingDatatable=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // DATATABLE POLYGON
        getDataTablePolygon(){
          
            this.loadingDatatable2=true;
            // this.$refs.filter.loading = true;
            this.page2 = (this.offset2 / this.rows2) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/cluster-beat',
                params: {
                    "search" : this.search2,
                    "per_page" : this.rows2,
                    "page" : this.page2,
                    "order_by" : this.field2,
                    "sort_by" : this.sort2,
                    "cluster_id": this.item.trans_cluster.cluster_id,
                    "clusterbeat_id": this.filters.clusterbeat_id,
                    "project_id": this.item.trans_cluster.project_id,
                }
            })
            .then(res => {
              this.data2 = res.data.data.data;
              this.dataTable2 = numberingDatatable(res.data.data.data, this.page2, this.rows2);
              this.totalItemsCount2 = res.data.data.total;
              this.rows2 = parseInt(res.data.data.per_page);
              this.loadingDatatable2=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loadingDatatable2=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSortPolygon(event) {
            this.field2 = event.sortField;
            this.sort2 = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTablePolygon();
        },
        //MAP
        initMap(){
            // eslint-disable-next-line no-undef
            this.map = L.map('map-without-polygon', {
                attributionControl: false,
                doubleClickZoom: true,
                zoomControl: true,
                zoom:1,
                zoomAnimation:false,
                fadeAnimation:true,
                markerZoomAnimation:true,
            }).setView([this.lat, this.lng], this.zoom);

            // eslint-disable-next-line no-undef
            L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap'
            }).addTo(this.map);
        },
        showMarker(){
            markers.clearLayers();

            this.restartMap();

            this.loading= true;

            this.location_list = [];

            this.marker_list = [];

            this.forms.outlet_ids = [];

            // var self = this;
           
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-beat/maps',
                params: {
                    "project_id": this.item.trans_cluster.project_id,
                    "cluster_id": this.item.trans_cluster.cluster_id,
                    "clusterbeat_id": this.filters.clusterbeat_id,
                }
            })
            .then(res => {
                this.location_list = res.data.data;
                this.location_total = res.data.data.length;

                this.dist_lat= this.location_list.length > 0 ? this.location_list[0].dist.lat : '0';
                this.dist_lng= this.location_list.length > 0 ? this.location_list[0].dist.lng : '0';

                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.outlet_latitude != null && value.outlet_longitude != null){

                        var htmlcontent = "<b>Cluster</b> : " + value.cluster_name + "</b><br/>";
                            htmlcontent += "<b>Outlet Type</b> : " + value.outlet_type_name + "<br/>";
                            htmlcontent += "<b>Beat Name</b> : " + value.clusterbeat_name + "<br/>";
                            htmlcontent += "<b>Outlet Code</b> : " + value.outlet_code + "<br/>";
                            htmlcontent += "<b>Outlet Name</b> : " + value.outlet_name + "<br/>";
                            htmlcontent += "<b>Total Sales</b> : " + value.outlet_sales_volume + "<br/>";
                            htmlcontent += "<b>Working Hours</b> : " + value.working_hours + "<br/>";
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Select</button>`
                        
                        const myCustomColour = value.clusterbeat_color;

                        const markerHtmlStyles = `
                            background-color: ${myCustomColour};
                            width: 3rem;
                            height: 3rem;
                            display: block;
                            left: -1.5rem;
                            top: -1.5rem;
                            position: relative;
                            border-radius: 3rem 3rem 0;
                            transform: rotate(45deg);
                            border: 1px solid #FFFFFF`

                        // eslint-disable-next-line no-undef
                        const icon = L.divIcon({
                            className: "my-custom-pin",
                            outlet_id: value.outlet_id,
                            cluster_name: value.cluster_name,
                            outlet_type_name: value.outlet_type_name,
                            clusterbeat_name: value.clusterbeat_name,
                            outlet_code: value.outlet_code,
                            outlet_name: value.outlet_name,
                            outlet_sales_volume: value.outlet_sales_volume,
                            working_hours: value.working_hours,
                            clusterbeat_color: value.clusterbeat_color,
                            iconAnchor: [0, 24],
                            labelAnchor: [-6, 0],
                            popupAnchor: [0, -36],
                            html: `<span style="${markerHtmlStyles}" />`
                        })

                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                            {
                                icon: icon,
                                info: value
                            });


                        // eslint-disable-next-line no-unused-vars
                        // marker.bindPopup(htmlcontent).addTo(self.map);

                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                        
                    }
                },(this)); 
                
                this.map.addLayer(markers);
                
                var markers_result = markers.getLayers().filter(function (layer) {
                    // eslint-disable-next-line no-undef
                    return layer instanceof L.Marker;
                });

                this.marker_list = markers_result;
                    
                var clusters = {};

                var filteredLocations = this.location_list.filter(function(value) {

                    // return value.cluster === 261 || value.cluster === 179 || value.cluster === 207 && value.latitude != null && value.longitude != null;
                    return value.outlet_latitude != null && value.outlet_longitude != null;
                });

                // Objek untuk menyimpan warna untuk setiap cluster
                var clusterColors = {};

                // Initialize an object to store tooltip data for each cluster
                var clusterData = {};

                filteredLocations.forEach((value) => {
                    if (!clusters[value.clusterbeat_id]) {
                        clusters[value.clusterbeat_id] = [];
                    }
                    clusters[value.clusterbeat_id].push([value.outlet_latitude, value.outlet_longitude]);

                    // Simpan warna untuk setiap cluster
                    if (!clusterColors[value.clusterbeat_id]) {
                        clusterColors[value.clusterbeat_id] = value.clusterbeat_color;
                    }

                    // Initialize or update clusterData
                    if (!clusterData[value.clusterbeat_id]) {
                        clusterData[value.clusterbeat_id] = {
                            total_outlet: 0,
                            total_sales: 0,
                            total_working_hours: 0,
                            label_cluster: null
                        };
                    }
                    clusterData[value.clusterbeat_id].total_outlet = value.total_outlet;
                    clusterData[value.clusterbeat_id].total_sales = value.total_sales;
                    clusterData[value.clusterbeat_id].total_working_hours = value.total_working_hours;
                    clusterData[value.clusterbeat_id].label_cluster = value.cluster_name +'-'+value.clusterbeat_name;
                });

                this.bounds = null;

                for (var clusterId in clusters) {
                    var clusterPoints = clusters[clusterId];

                    // Hitung convex hull dari titik-titik dalam cluster
                    var hullPoints = this.convexHull(clusterPoints);

                    // Ambil warna untuk cluster tertentu
                    var color = clusterColors[clusterId];

                    // Buat tooltip content untuk polygon
                    var tooltipContent = `
                        <b>${clusterData[clusterId].label_cluster}</b><br/>
                        <b>Total Outlets:</b> ${clusterData[clusterId].total_outlet}<br/>
                        <b>Total Sales:</b> ${clusterData[clusterId].total_sales}<br/>
                        <b>Total Working Hours:</b> ${clusterData[clusterId].total_working_hours}
                    `;

                    // Buat polygon dari convex hull dengan warna sesuai data JSON
                    // eslint-disable-next-line no-undef
                    var polygon = L.polygon(hullPoints, { fillColor: color, fillOpacity: 0.5 }).addTo(this.map);

                    // Tambahkan popup ke polygon yang muncul saat diklik
                    polygon.bindPopup(tooltipContent);
                    
                    this.polygons.push(polygon); // Add polygon to polygons array

                    // Update bounds with polygon bounds
                    var polygonBounds = polygon.getBounds();
                    if (this.bounds) {
                        this.bounds.extend(polygonBounds);
                    } else {
                        this.bounds = polygonBounds;
                    }
                }

                // Fit map to bounds
                if (this.bounds) {
                    this.map.fitBounds(this.bounds);
                }

                this.loading= false;
                
                // eslint-disable-next-line no-undef
                L.marker([this.dist_lat, this.dist_lng]).addTo(this.map);
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        zoomToCoordinate(latitude, longitude) {
            if (latitude && longitude) {
                this.map.setView([latitude, longitude], 30); // Zoom to the coordinate with a zoom level of 15
            }
        },
        zoomToCoordinatePolygon(color) {
            let polygon = this.polygons.find(p => p.options.fillColor === color);
            if (polygon) {
                let bounds = polygon.getBounds();
                this.map.fitBounds(bounds);
            }
        },
        removeOutletFromIcons(e){
            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == e.value[0].id) {

                    const myCustomColour = marker.options.icon.options.clusterbeat_color;

                    const markerHtmlStyles = `
                        background-color: ${myCustomColour};
                        width: 3rem;
                        height: 3rem;
                        display: block;
                        left: -1.5rem;
                        top: -1.5rem;
                        position: relative;
                        border-radius: 3rem 3rem 0;
                        transform: rotate(45deg);
                        border: 1px solid #FFFFFF`

                    // eslint-disable-next-line no-undef
                    var icon = L.divIcon({
                        className: "my-custom-pin",
                        outlet_id: marker.options.icon.options.outlet_id,
                        cluster_name: marker.options.icon.options.cluster_name,
                        outlet_type_name: marker.options.icon.options.outlet_type_name,
                        clusterbeat_name: marker.options.icon.options.clusterbeat_name,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        outlet_sales_volume: marker.options.icon.options.outlet_sales_volume,
                        working_hours: marker.options.icon.options.working_hours,
                        clusterbeat_color: marker.options.icon.options.clusterbeat_color,
                        iconAnchor: [0, 24],
                        labelAnchor: [-6, 0],
                        popupAnchor: [0, -36],
                        html: `<span style="${markerHtmlStyles}" />`
                    })

                    var htmlcontent = "<b>Cluster</b> : " + marker.options.icon.options.cluster_name + "</b><br/>";
                        htmlcontent += "<b>Outlet Type</b> : " + marker.options.icon.options.outlet_type_name + "<br/>";
                        htmlcontent += "<b>Beat Name</b> : " + marker.options.icon.options.clusterbeat_name + "<br/>";
                        htmlcontent += "<b>Outlet Code</b> : " + marker.options.icon.options.outlet_code + "<br/>";
                        htmlcontent += "<b>Outlet Name</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                        htmlcontent += "<b>Total Sales</b> : " + marker.options.icon.options.outlet_sales_volume + "<br/>";
                        htmlcontent += "<b>Working Hours</b> : " + marker.options.icon.options.working_hours + "<br/>";
                        htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();getOutletFromId(${marker.options.icon.options.outlet_id});' >Select</button>`
                    
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }
            return this.forms.outlet_ids;
        },
        getOutletFromId(id) {

            var outlet_name = "";

            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == id) {
                    outlet_name = marker.options.icon.options.outlet_name;

                    const myCustomColour = "red";

                    const markerHtmlStyles = `
                        background-color: ${myCustomColour};
                        width: 3rem;
                        height: 3rem;
                        display: block;
                        left: -1.5rem;
                        top: -1.5rem;
                        position: relative;
                        border-radius: 3rem 3rem 0;
                        transform: rotate(45deg);
                        border: 1px solid #FFFFFF`

                    // eslint-disable-next-line no-undef
                    var icon = L.divIcon({
                        className: "my-custom-pin",
                        outlet_id: marker.options.icon.options.outlet_id,
                        cluster_name: marker.options.icon.options.cluster_name,
                        outlet_type_name: marker.options.icon.options.outlet_type_name,
                        clusterbeat_name: marker.options.icon.options.clusterbeat_name,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        outlet_sales_volume: marker.options.icon.options.outlet_sales_volume,
                        working_hours: marker.options.icon.options.working_hours,
                        clusterbeat_color: marker.options.icon.options.clusterbeat_color,
                        iconAnchor: [0, 24],
                        labelAnchor: [-6, 0],
                        popupAnchor: [0, -36],
                        html: `<span style="${markerHtmlStyles}" />`
                    })

                    var htmlcontent = "<b>Cluster</b> : " + marker.options.icon.options.cluster_name + "</b><br/>";
                        htmlcontent += "<b>Outlet Type</b> : " + marker.options.icon.options.outlet_type_name + "<br/>";
                        htmlcontent += "<b>Beat Name</b> : " + marker.options.icon.options.clusterbeat_name + "<br/>";
                        htmlcontent += "<b>Outlet Code</b> : " + marker.options.icon.options.outlet_code + "<br/>";
                        htmlcontent += "<b>Outlet Name</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                        htmlcontent += "<b>Total Sales</b> : " + marker.options.icon.options.outlet_sales_volume + "<br/>";
                        htmlcontent += "<b>Working Hours</b> : " + marker.options.icon.options.working_hours + "<br/>";
                        htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${marker.options.icon.options.outlet_id});' >Unselect</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            let data = {
                "id": id,
                "name": outlet_name,
            };

            this.addOutlet(data)
        },
        addOutlet(data) {
            var a = this.forms.outlet_ids.push(data);
            return a;
        },
        removeOutletFromIcon(id) {
            this.forms.outlet_ids = this.forms.outlet_ids.filter(outlet => outlet.id !== id);

            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == id) {

                    const myCustomColour = marker.options.icon.options.clusterbeat_color;

                    const markerHtmlStyles = `
                        background-color: ${myCustomColour};
                        width: 3rem;
                        height: 3rem;
                        display: block;
                        left: -1.5rem;
                        top: -1.5rem;
                        position: relative;
                        border-radius: 3rem 3rem 0;
                        transform: rotate(45deg);
                        border: 1px solid #FFFFFF`

                    // eslint-disable-next-line no-undef
                    var icon = L.divIcon({
                        className: "my-custom-pin",
                        outlet_id: marker.options.icon.options.outlet_id,
                        cluster_name: marker.options.icon.options.cluster_name,
                        outlet_type_name: marker.options.icon.options.outlet_type_name,
                        clusterbeat_name: marker.options.icon.options.clusterbeat_name,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        outlet_sales_volume: marker.options.icon.options.outlet_sales_volume,
                        working_hours: marker.options.icon.options.working_hours,
                        clusterbeat_color: marker.options.icon.options.clusterbeat_color,
                        iconAnchor: [0, 24],
                        labelAnchor: [-6, 0],
                        popupAnchor: [0, -36],
                        html: `<span style="${markerHtmlStyles}" />`
                    })

                    var htmlcontent = "<b>Cluster</b> : " + marker.options.icon.options.cluster_name + "</b><br/>";
                        htmlcontent += "<b>Outlet Type</b> : " + marker.options.icon.options.outlet_type_name + "<br/>";
                        htmlcontent += "<b>Beat Name</b> : " + marker.options.icon.options.clusterbeat_name + "<br/>";
                        htmlcontent += "<b>Outlet Code</b> : " + marker.options.icon.options.outlet_code + "<br/>";
                        htmlcontent += "<b>Outlet Name</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                        htmlcontent += "<b>Total Sales</b> : " + marker.options.icon.options.outlet_sales_volume + "<br/>";
                        htmlcontent += "<b>Working Hours</b> : " + marker.options.icon.options.working_hours + "<br/>";
                        htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();getOutletFromId(${marker.options.icon.options.outlet_id});' >Select</button>`
                    
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }
            return this.forms.outlet_ids;
        },
        convexHull(points) {
            if (points.length < 3) return points;

            // Cari titik paling kiri
            var leftmost = points.reduce(function(min, p) {
                return p[0] < min[0] ? p : min;
            }, points[0]);

            var hull = [];
            var pointOnHull = leftmost;

            do {
                hull.push(pointOnHull);
                var endpoint = points[0];

                for (var i = 1; i < points.length; i++) {
                    if (endpoint === pointOnHull || this.isCounterClockwise(pointOnHull, endpoint, points[i])) {
                        endpoint = points[i];
                    }
                }

                pointOnHull = endpoint;
            } while (pointOnHull !== leftmost);

            return hull;
        },
        isCounterClockwise(p1, p2, p3) {
            return (p2[1] - p1[1]) * (p3[0] - p2[0]) > (p2[0] - p1[0]) * (p3[1] - p2[1]);
        },
        restartMap() {
            markers.clearLayers();
            this.polygons.forEach(polygon => this.map.removeLayer(polygon));
            this.polygons = [];
        },
        toggleMarkers() {
            if (this.buttonShowMarker == "Show Marker") {
                this.showMarkers();
            } else {
                this.removeMarkers();
            }
        },
        // New functions to remove and show markers based on button clicks
        removeMarkers() {
            markers.clearLayers(); // Remove all markers from the map
        },
        // Function to show markers from the saved marker_list array
        showMarkers() {
            // Re-add each saved marker back to the map
            this.marker_list.forEach(marker => {
                markers.addLayer(marker);
            });
            this.map.addLayer(markers); // Add the marker layer back to the map
        },
        refreshMap() {
            this.map.invalidateSize();
        },
    }
    
}
</script>

<style>
	.map-without-polygon {
		height: 550px;
        width: 100%;
	}
    .datatable-container {
        height: 550px;
        overflow-y: auto;
    }
</style>